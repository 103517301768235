import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@c/snack-bar/snack-bar.component';
import {
  TradingLogSymbolSummaryPanelItemValuesModel,
  TradingLogSymbolSummaryPanelStateType,
} from '@c/trading-log/shared';
import { IDropdownApi } from '@chart/charting_library';
import {
  ChartHistoryIntervalMonths,
  ChartIntervals,
  ChartSaveData,
  EasternTimeZoneName,
  ExchangeCountriesCodes,
  Features,
  LowestCloseLineIndicatorOptions,
  LowestHighestCloseLineIndicatorOptions,
  PowerXStrategyIndicatorOptions,
  StorageKeys,
  Themes,
  UserSettings,
  expectedMove1LineColor,
  expectedMove2LineColor,
  round,
} from '@const';
import { BarColor } from '@core/business/trading-chart/bar-color';
import { JanIndicator } from '@core/business/trading-chart/jan-indicator';
import { LowestCloseLine } from '@core/business/trading-chart/lowest-close-line';
import { IChartSaveData, IMarketData, ISymbolData } from '@core/types';
import { ObservableService as ObservableServiceV1 } from '@core1/directives/observable.service';
import { IDrawExpectedMoveLineParams } from '@m1/wheel/wheel-chart/wheel-chart.model';
import { WheelService } from '@m1/wheel/wheel.service';
import { IExpectedMove } from '@mod/data/expected-move.model';
import { EditionsService } from '@s/editions.service';
import { ExpectedMoveService } from '@s/expected-move.service';
import { HistoricalDataService } from '@s/historical-data.service';
import { LocalStorageService } from '@s/local-storage.service';
import { MarketTimeService } from '@s/market-time.service';
import { ObservableService } from '@s/observable.service';
import { ProcessedDataService } from '@s/processed-data.service';
import { StreamingService } from '@s/streaming.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { TradingChartService } from '@s/trading-chart.service';
import { UserDataService } from '@s/user-data.service';
import { IMySettings } from '@s/user-settings.service';
import { IWheelFilter } from '@t/wheel/wheel.types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, take } from 'rxjs/operators';
import {
  ChartingLibraryWidgetOptions,
  DatafeedConfiguration,
  EntityId,
  IBasicDataFeed,
  IChartWidgetApi,
  IChartingLibraryWidget,
  ResolutionString,
  SetVisibleTimeRange,
  widget,
} from 'src/assets/charting_library';

import { breakEvenLineColor, costBasisLineColor } from '@constants/chart';
import { CustomChartLineDataModel } from '@mod/chart';

interface IStrikePriceShapeData {
  minStrikePriceLineId: EntityId | null;
  symbolData: ISymbolData;
  symbolFullData: ISymbolData[];
  filteredData: ISymbolData[];
  filters: IWheelFilter;
}

interface ISaveChartShapes {
  symbolId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: { shapes: Array<Record<string, any>>; isError: boolean };
}

const pixelCountToSetScaleRangeForMinStrike = 30;

@Component({
  selector: 'app-wheel-chart',
  templateUrl: './wheel-chart.component.html',
  styleUrls: ['./wheel-chart.component.scss'],
})
export class WheelChartComponent implements OnInit, OnDestroy {
  public features = Features;

  constructor(
    protected observableService: ObservableService,
    private localStorageService: LocalStorageService,
    private historicalDataService: HistoricalDataService,
    private symbolService: SymbolsService,
    private processedDataService: ProcessedDataService,
    private streamingService: StreamingService,
    private userDataService: UserDataService,
    private observableServiceV1: ObservableServiceV1,
    private tradingChartService: TradingChartService,
    private wheelService: WheelService,
    private expectedMoveService: ExpectedMoveService,
    private marketTimeService: MarketTimeService,
    private editionsService: EditionsService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
  ) {}

  get minStrikeText(): string {
    let strikeText = 'Min Strike: ';
    this.strikePriceForShapeData.filteredData
      .filter((data) => data.strike_price === this.strikePriceForShapeData.symbolData.strike_price)
      .sort((a, b) => moment(a.expiration).unix() - moment(b.expiration).unix())
      .forEach((data, index) => {
        strikeText += `${index === 0 ? '' : ' / '}${moment(data.expiration).format('MMM D')} - ${data.annularized}%`;
      });
    return strikeText;
  }

  private _user: IMySettings = null;
  private _selectedSymbol: ISymbol | null = null;
  private _activeChart: IChartWidgetApi = null;
  private _tvWidget: IChartingLibraryWidget | null = null;
  private _barColorIndicatorId: EntityId | null = null;
  private _janIndicatorId: EntityId | null = null;
  private _preMarketShapeId: EntityId | null = null;
  private _saveChartShapesSubject = new Subject<ISaveChartShapes>();
  private _listenToDrawEvent = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _lastSavedShapesState: Array<Record<string, any>> | null = null;
  private _lowestHighestCloseIndicatorDropdown: IDropdownApi = null;
  private _lowestCloseIndicatorDropdown: IDropdownApi = null;
  private _powerxStrategyIndicatorDropdown: IDropdownApi = null;

  private _expectedMoveOneUpShapeId: EntityId | null = null;
  private _expectedMoveOneDownShapeId: EntityId | null = null;
  private _expectedMoveTwoUpShapeId: EntityId | null = null;
  private _expectedMoveTwoDownShapeId: EntityId | null = null;
  private _expectedMoveOne: IExpectedMove | null = null;
  private _expectedMoveTwo: IExpectedMove | null = null;
  private _expectedMoveInitialized = false;

  private _subscriber = new Subscription();
  private currentVisibleRange: SetVisibleTimeRange = {
    from: moment().subtract(12, 'month').unix(),
    to: moment().add(2, 'week').unix(),
  };
  private recentMarketUpdate: IMarketData = null;
  private recentExpectedMove: IMarketData = null;

  isWheelPremiums = false;
  isWheelCalculator = false;
  chartSaveData: IChartSaveData = {
    indicators: [],
    chartType: 0,
    timeZone: 'Etc/UTC',
    priceScaleMode: 0,
  };

  Datafeed: IBasicDataFeed;
  strikePriceForShapeData: IStrikePriceShapeData = {
    minStrikePriceLineId: null,
    symbolData: null,
    symbolFullData: null,
    filteredData: [],
    filters: null,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  supportedResolutions: any = [ChartIntervals.Daily];

  chartConfig: DatafeedConfiguration = {
    supported_resolutions: this.supportedResolutions,
    supports_marks: true,
    supports_timescale_marks: true,
  };
  private minStrikeColorForDarkTheme = '#D680FF';
  private minStrikeColorForWhiteTheme = '#A100ED';
  private minStrikeColor: string = this.minStrikeColorForWhiteTheme;

  private _subscriberData = {};

  protected showAdvancedLowestHighestCloseLine = false;

  private _breakEvenLineData: CustomChartLineDataModel = {
    color: breakEvenLineColor,
    lineText: '',
  };

  private _costBasisLineData: CustomChartLineDataModel = {
    color: costBasisLineColor,
    lineText: '',
  };

  private tradingLogSymbolSummaryPanelItemValuesModel: TradingLogSymbolSummaryPanelItemValuesModel = {};

  async ngOnInit(): Promise<void> {
    this._user = this.observableService.mySettings.getValue();
    this.showAdvancedLowestHighestCloseLine = this.editionsService.isFeatureAvailable(Features.LowHighIndicator);

    this.strikePriceForShapeData.filters = this.observableServiceV1.wheelFilters.getValue();
    const symbol = this.observableService.wheelSymbol.getValue();
    const [selectedSymbol, chartSaveDataFromDB] = await Promise.all([
      this.symbolService.getById(symbol),
      this.userDataService.getAsJSON(ChartSaveData.Wheel),
    ]);
    this.chartSaveData = chartSaveDataFromDB || {};
    this._selectedSymbol = selectedSymbol;

    this._subscriber.add(
      this._saveChartShapesSubject.subscribe(({ symbolId, state: { shapes, isError } }) => {
        const stateToSave = shapes.length > 0 ? shapes : null;

        if (_.isEqual(this._lastSavedShapesState, stateToSave)) {
          return;
        }

        if (isError) {
          // run onMicrotaskEmpty and inside setTimeout
          // to avoid specific for combination of chart and snackBar bug
          this.ngZone.onMicrotaskEmpty.pipe(take(1), delay(200)).subscribe(() => {
            this.showPopUpOnError();
          });
        }

        this._lastSavedShapesState = stateToSave;
        this.userDataService.set(this.getChartKey(symbolId), stateToSave);
      }),
    );

    this._subscriber.add(
      this.observableService.wheelSymbol.subscribe(async (symbolId) => {
        if (this._selectedSymbol && this._selectedSymbol.security_id === symbolId) {
          return;
        }

        this.tradingLogSymbolSummaryPanelItemValuesModel = {};
        this._selectedSymbol = await this.symbolService.getById(symbolId);
        this.recentMarketUpdate = null;
        this.recentExpectedMove = null;

        if (this._selectedSymbol) {
          await this.loadChart();
        }

        if (this._preMarketShapeId && this._activeChart) {
          this._activeChart.removeEntity(this._preMarketShapeId);
          this._preMarketShapeId = null;
        }
      }),
    );

    this._subscriber.add(
      this.wheelService.optionsForMinStrike$.subscribe(async (optionsForMinStrike) => {
        this.strikePriceForShapeData.symbolData = null;

        // TODO: use id or symbol-name instead of options-array ??
        if (optionsForMinStrike !== null && optionsForMinStrike.length > 0) {
          // TODO: check is situation when optionsForMinStrike with invalid security_id were received possible
          const symbolData = await this.symbolService.getById(optionsForMinStrike[0]?.security_id);

          if (symbolData?.security_id) {
            this.strikePriceForShapeData.symbolFullData = _.sortBy(optionsForMinStrike, (o) => o.strike_price);
            this.strikePriceForShapeData.filteredData = _.sortBy(optionsForMinStrike, (o) => o.strike_price);
            this.strikePriceForShapeData.symbolData = this.strikePriceForShapeData.symbolFullData[0];
          } else {
            this.strikePriceForShapeData.symbolFullData = null;
            this.strikePriceForShapeData.filteredData = [];
            this.strikePriceForShapeData.symbolData = null;
          }
        } else {
          this.strikePriceForShapeData.symbolFullData = null;
          this.strikePriceForShapeData.filteredData = [];
          this.strikePriceForShapeData.symbolData = null;
        }

        if (this._activeChart && this._selectedSymbol) {
          this.renderMinStrikeLine();
        }
      }),
    );

    this._subscriber.add(
      this.observableServiceV1.wheelFilters.subscribe((res: IWheelFilter) => {
        this.strikePriceForShapeData.filters = res;
        if (this.strikePriceForShapeData.symbolData) {
          this.renderMinStrikeLine();
        }
      }),
    );

    this._subscriber.add(
      this.observableService.lowestHighestCloseLineIndicatorWheel.subscribe(async () => {
        await this.createLowestHighestCloseIndicator();
      }),
    );

    this._subscriber.add(
      this.observableService.lowestCloseLineIndicatorWheel.subscribe(async () => {
        await this.createLowestHighestCloseIndicator();
      }),
    );

    this._subscriber.add(
      this.observableService.showExpectedMoveOneOnWheel.pipe(distinctUntilChanged()).subscribe(async () => {
        await this.initializeExpectedMove(true, false);
      }),
    );

    this._subscriber.add(
      this.observableService.showExpectedMoveTwoOnWheel.pipe(distinctUntilChanged()).subscribe(async () => {
        await this.initializeExpectedMove(false, true);
      }),
    );

    this._subscriber.add(
      this.observableService.expectedMoveLiveData.subscribe((data) => this.expectedMoveCallback(data, false)),
    );

    this._subscriber.add(
      this.observableService.showPowerXStrategyIndicatorWheel.subscribe(async () => {
        await this.createPowerXStrategyIndicator();
        await this.drawPowerxStrategyIndicatorDropdown();
      }),
    );

    await this.loadChart();
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  renderMinStrikeLine(): void {
    if (this.strikePriceForShapeData.filteredData.length !== 0) {
      this.strikePriceForShapeData.symbolData = this.strikePriceForShapeData.filteredData[0];
    }
    this.createMinStrikeLine();
  }

  async createIndicators(): Promise<void> {
    if (!this._activeChart) {
      return;
    }

    await this.createPowerXStrategyIndicator();
    await this.createLowestHighestCloseIndicator();
  }

  async createPowerXStrategyIndicator(): Promise<void> {
    if (!this._activeChart) {
      return;
    }

    this._barColorIndicatorId = this._activeChart.getAllStudies().find((study) => study.name === 'PowerX')?.id;

    if (this._barColorIndicatorId) {
      this._activeChart.removeEntity(this._barColorIndicatorId);
    }

    if (this.observableService.showPowerXStrategyIndicatorWheel.getValue()) {
      this._barColorIndicatorId = await this._activeChart.createStudy('PowerX', false, true);
    }
  }

  async createLowestHighestCloseIndicator(): Promise<void> {
    if (!this._activeChart) {
      return;
    }

    const indicatorName = this.showAdvancedLowestHighestCloseLine ? "Jan's indicator" : 'Lowest Close Line indicator';

    this._janIndicatorId = this._activeChart.getAllStudies().find((study) => study.name === indicatorName)?.id;

    if (this._janIndicatorId) {
      this._activeChart.removeEntity(this._janIndicatorId);
    }

    this._janIndicatorId = await this._activeChart.createStudy(
      indicatorName,
      false,
      true,
      {},
      { showLabelsOnPriceScale: false },
    );
  }

  getChartKey(symbolId: number): string {
    return `wheel-drawings-${symbolId}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserShapes(): { shapes: Array<Record<string, any>>; isError: boolean } {
    if (!this._activeChart) {
      return { shapes: [], isError: false };
    }

    const idsToExclude = [
      this.strikePriceForShapeData.minStrikePriceLineId,
      this._preMarketShapeId,
      this._expectedMoveOneUpShapeId,
      this._expectedMoveOneDownShapeId,
      this._expectedMoveTwoUpShapeId,
      this._expectedMoveTwoDownShapeId,
      this._breakEvenLineData.id,
      this._costBasisLineData.id,
    ];

    const shapes = this._activeChart
      .getAllShapes()
      .filter(({ id }) => !idsToExclude.includes(id))
      .map(({ name, id }) => {
        const shape = this._activeChart.getShapeById(id);

        return {
          points: shape.getPoints(),
          props: { id, shape: name, overrides: shape.getProperties() },
        };
      });

    const isError = !!shapes.find((item) => item.points.length === 0);

    return {
      shapes: shapes.filter((shape) => shape.points && shape.points.length > 0),
      isError,
    };
  }

  removeUserShapes(): void {
    if (!this._activeChart) {
      return;
    }

    this.getUserShapes().shapes.forEach(({ props }) => {
      this._activeChart.removeEntity(props.id);
    });
  }

  // show popup
  private showPopUpOnError(): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        icon: 'alert-error',
        message: 'Saving drawings failed. Please refresh the page and try again',
        snackbar: this.snackBar,
      },
      duration: 3000,
      horizontalPosition: 'left',
      panelClass: 'error',
    });
  }

  private async createLowestHighestCloseIndicatorDropdown(): Promise<void> {
    if (!this._tvWidget || !this.showAdvancedLowestHighestCloseLine) {
      return;
    }

    if (this._lowestHighestCloseIndicatorDropdown) {
      this._lowestHighestCloseIndicatorDropdown.remove();
    }

    const selectedOption = this.observableService.lowestHighestCloseLineIndicatorWheel.getValue();
    const items = [
      LowestHighestCloseLineIndicatorOptions.Both,
      LowestHighestCloseLineIndicatorOptions.LowestLine,
      LowestHighestCloseLineIndicatorOptions.HighestLine,
      LowestHighestCloseLineIndicatorOptions.None,
    ].map((option) => ({
      title: option,
      onSelect: async (): Promise<void> => {
        await this.userDataService.set(UserSettings.LowestHighestCloseLineIndicatorWheel, option);
        this._lowestHighestCloseIndicatorDropdown.applyOptions({
          title: `Lowest/Highest Close: ${option}`,
        });
      },
    }));

    this._lowestHighestCloseIndicatorDropdown = await this._tvWidget.createDropdown({
      title: `Lowest/Highest Close: ${selectedOption}`,
      items,
    });
  }

  private async createLowestCloseIndicatorDropdown(): Promise<void> {
    // do not show it if advancedLowestHighestCloseLine is available
    if (!this._tvWidget || this.showAdvancedLowestHighestCloseLine) {
      return;
    }

    if (this._lowestCloseIndicatorDropdown) {
      this._lowestCloseIndicatorDropdown.remove();
    }

    const selectedOption = this.observableService.lowestCloseLineIndicatorWheel.getValue();
    const items = [LowestCloseLineIndicatorOptions.LowestLine, LowestCloseLineIndicatorOptions.None].map((option) => ({
      title: option,
      onSelect: async (): Promise<void> => {
        await this.userDataService.set(UserSettings.LowestCloseLineIndicatorWheel, option);
        this._lowestCloseIndicatorDropdown.applyOptions({
          title: `Lowest Close: ${option}`,
        });
      },
    }));

    this._lowestCloseIndicatorDropdown = await this._tvWidget.createDropdown({
      title: `Lowest Close: ${selectedOption}`,
      items,
    });
  }

  private async drawPowerxStrategyIndicatorDropdown(): Promise<void> {
    if (!this._tvWidget) {
      return;
    }

    if (this._powerxStrategyIndicatorDropdown) {
      this._powerxStrategyIndicatorDropdown.remove();
    }

    const items = [PowerXStrategyIndicatorOptions.Show, PowerXStrategyIndicatorOptions.None].map((option) => ({
      title: option,
      onSelect: async (): Promise<void> => {
        const showIndicator = option === PowerXStrategyIndicatorOptions.Show;
        await this.userDataService.set(UserSettings.ShowPowerXStrategyIndicatorWheel, showIndicator);
        this._powerxStrategyIndicatorDropdown.applyOptions({
          title: `PowerX Strategy: ${option}`,
        });
      },
    }));

    const selectedOption = this.observableService.showPowerXStrategyIndicatorWheel.getValue()
      ? PowerXStrategyIndicatorOptions.Show
      : PowerXStrategyIndicatorOptions.None;
    this._powerxStrategyIndicatorDropdown = await this._tvWidget.createDropdown({
      title: `PowerX Strategy: ${selectedOption}`,
      items,
    });
  }

  async loadChart(): Promise<void> {
    const [savedState] = await Promise.all([
      this.userDataService.getAsJSON(this.getChartKey(this._selectedSymbol.security_id)),
      this.processedDataService.get(this._selectedSymbol.security_id),
      this.historicalDataService.get(this._selectedSymbol.security_id),
    ]);

    this._lastSavedShapesState = null;

    if (this._activeChart && savedState) {
      try {
        this._listenToDrawEvent = false;
        this.currentVisibleRange = this._activeChart.getVisibleRange();

        this.removeUserShapes();
        this.removeMinStrikePriceLine(); // remove line before symbol changing

        this.removeLine(this._breakEvenLineData?.id);
        this.removeLine(this._costBasisLineData?.id);
        this._breakEvenLineData.id = null;
        this._costBasisLineData.id = null;

        this._activeChart.setSymbol(
          `${this._selectedSymbol.symbol}.${this._selectedSymbol.exchange_code}`,
          async () => {
            this.renderMinStrikeLine();

            this.addSummaryPanelLines(); // add or redraw Summary panel lines

            await this._activeChart.setVisibleRange({
              from: moment().subtract(60, 'month').unix(),
              to: moment().unix(),
            });
            this.restoreUserShapes(savedState);
            await this._activeChart.setVisibleRange(this.currentVisibleRange);

            this._listenToDrawEvent = true;
            setTimeout(async () => {
              this.setScaleRange();
              await this.initializeExpectedMove(false, false);
            }, 0);
          },
        );

        return;
      } catch (error) {
        console.warn({ message: 'Cannot restore saved wheel chart shapes', savedState, error });
      }
    }

    if (this._activeChart) {
      this._listenToDrawEvent = false;

      this.removeUserShapes();
      this.removeMinStrikePriceLine(); // remove line before symbol changing

      this.removeLine(this._breakEvenLineData?.id);
      this.removeLine(this._costBasisLineData?.id);
      this._breakEvenLineData.id = null;
      this._costBasisLineData.id = null;

      this._activeChart.setSymbol(`${this._selectedSymbol.symbol}.${this._selectedSymbol.exchange_code}`, () => {
        this.renderMinStrikeLine();
        this.addSummaryPanelLines(); // add or redraw Summary panel lines

        this._listenToDrawEvent = true;
        setTimeout(async () => {
          this.setScaleRange();
          await this.initializeExpectedMove(false, false);
        }, 0);
      });

      return;
    }

    this.dataFeedMethod();

    const localStorageService = this.localStorageService;
    const observableService = this.observableService;

    const getLowestHighestOption = (): string => this.observableService.lowestHighestCloseLineIndicatorWheel.getValue();
    const getLowestOption = (): string => this.observableService.lowestCloseLineIndicatorWheel.getValue();
    const getProcessedDataStorageKey = (): string => `${StorageKeys.ProcessedData}_${this._selectedSymbol.security_id}`;
    const getHistoricalDataStorageKey = (): string =>
      `${StorageKeys.HistoricalData}_${this._selectedSymbol.security_id}`;

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this._selectedSymbol.symbol,
      datafeed: this.Datafeed,
      interval: ChartIntervals.Daily as ResolutionString,
      container: 'tv_chart_container_wheel',
      library_path: './assets/charting_library/',
      locale: 'en',
      auto_save_delay: 0.02,
      disabled_features: [
        'header_screenshot',
        'header_symbol_search',
        'header_undo_redo',
        'header_settings',
        'header_saveload',
        'header_resolutions',
        'header_compare',
        'control_bar',
        // TODO: verify  missing disabled features are actually off
        'show_hide_button_in_legend',
        'format_button_in_legend',
        'legend_context_menu',
        'main_series_scale_menu',
        // 'header-toolbar-intervals',
        'pane_context_menu',
        'header_fullscreen_button',
        'scales_context_menu',
        'display_market_status',
        'symbol_info',
        'symbol_search_hot_key',
        'show_object_tree',
        'go_to_date',
        'create_volume_indicator_by_default',
        'create_volume_indicator_by_default_once',
      ],
      enabled_features: [
        'chart_style_hilo',
        'charting_library_debug_mode',
        'left_toolbar',
        'use_localstorage_for_settings',
      ],
      drawings_access: {
        type: 'black',
        tools: [
          { name: 'Brush', grayed: false },
          { name: 'Anchored Text', grayed: false },
          { name: 'Anchored Note', grayed: false },
          { name: 'Double Curve', grayed: false },
          { name: 'Curve', grayed: false },
          { name: 'Fib Speed Resistance Arcs', grayed: false },
          { name: 'Highlighter', grayed: false },
          { name: 'Path', grayed: false },
        ],
      },
      time_frames: [
        { text: '3m', resolution: '1D' as ResolutionString, description: '3 Months', title: '3M' },
        { text: '6m', resolution: '1D' as ResolutionString, description: '6 Months', title: '6M' },
        { text: '1y', resolution: '1D' as ResolutionString, description: '1 Year', title: '1Y' },
        { text: '2y', resolution: '1D' as ResolutionString, description: '2 Years', title: '2Y' },
      ],
      timezone: 'Etc/UTC',
      fullscreen: false,
      autosize: true,
      debug: false,
      overrides: {
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showPrevClosePriceLine': false,
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.legendProperties.showSeriesTitle': false,
        'mainSeriesProperties.barStyle.thinBars': false,
      },

      custom_indicators_getter: (PineJS) => {
        return Promise.resolve([
          new BarColor(observableService).ploatBarColor(
            PineJS,
            'PowerX',
            'PowerX Strategy',
            getProcessedDataStorageKey,
            localStorageService,
          ),
          this.showAdvancedLowestHighestCloseLine
            ? new JanIndicator(getLowestHighestOption, localStorageService).ploat(PineJS, getHistoricalDataStorageKey)
            : new LowestCloseLine(getLowestOption, observableService, localStorageService).ploatLowestCloseLine(
                PineJS,
                getHistoricalDataStorageKey,
              ),
        ]);
      },
    };

    if (!document.getElementById(widgetOptions.container.toString())) {
      return;
    }

    console.log('wheel chart - before _tvWidget ctor');

    this._tvWidget = new widget(widgetOptions);
    this._tvWidget.onChartReady(async () => {
      console.log('wheel chart - _tvWidget.onChartReady');

      this._activeChart = this._tvWidget.activeChart();
      console.log('wheel chart - _tvWidget.activeChart');

      this._activeChart.getTimezoneApi().setTimezone(this.chartSaveData.timeZone || EasternTimeZoneName);
      console.log('wheel chart - _activeChart.setTimezone ' + this.chartSaveData.timeZone || EasternTimeZoneName);

      // create volume indicator only once for current user
      const showVolumeIndicator = this.observableService.showVolumeIndicatorOnStartForWheel.getValue();
      const isVolumeIndicatorInSavedData =
        this.chartSaveData?.indicators?.length > 0 &&
        this.chartSaveData.indicators.some((item) => item.name === 'Volume');
      if (showVolumeIndicator && !isVolumeIndicatorInSavedData) {
        await Promise.all([
          this._activeChart.createStudy('Volume', true, false),
          this.userDataService.set(UserSettings.ShowVolumeIndicatorOnStartForWheel, false),
        ]);
      }

      await this._activeChart.setVisibleRange({
        from: moment().subtract(60, 'month').unix(),
        to: moment().unix(),
      });
      console.log('wheel chart - _activeChart.setVisibleRange');

      this.restoreUserShapes(savedState);
      console.log('wheel chart - restoreUserShapes');

      this._activeChart.setChartType(this.chartSaveData.chartType || 0);
      console.log('wheel chart - _activeChart.setChartType');

      await this.createIndicators();
      console.log('wheel chart - createIndicators');

      await this.tradingChartService.restoreUserIndicators(this.chartSaveData, this._activeChart);
      console.log('wheel chart - tradingChartService.restoreUserIndicators');

      this.renderMinStrikeLine();
      console.log('wheel chart - renderMinStrikeLine');

      await this.createLowestHighestCloseIndicatorDropdown();
      console.log('wheel chart - createLowestHighestCloseIndicatorDropdown');

      await this.createLowestCloseIndicatorDropdown();
      console.log('wheel chart - createLowestCloseIndicatorDropdown');

      await this.drawPowerxStrategyIndicatorDropdown();
      console.log('wheel chart - drawPowerxStrategyIndicatorDropdown');

      this._tvWidget.applyOverrides({
        'mainSeriesProperties.priceAxisProperties.log': this.chartSaveData.priceScaleMode === 1,
        'mainSeriesProperties.priceAxisProperties.percentage': this.chartSaveData.priceScaleMode === 2,
      });
      console.log('wheel chart - _tvWidget.applyOverrides');

      await this.initializeExpectedMove(false, false);
      console.log('wheel chart - initializeExpectedMove');

      this.addSummaryPanelLines(); // add or redraw Summary panel lines in case when it was added before chart is ready
      console.log('wheel chart - addSummaryPanelLines');

      await this._activeChart.setVisibleRange(this.currentVisibleRange);
      console.log('wheel chart - _activeChart.setVisibleRange');

      this._subscriber.add(
        this.observableService.theme.subscribe(async (value) => {
          console.log('wheel chart - change theme callback');

          this._tvWidget.applyOverrides({
            'mainSeriesProperties.priceAxisProperties.log': this.chartSaveData.priceScaleMode === 1,
            'mainSeriesProperties.priceAxisProperties.percentage': this.chartSaveData.priceScaleMode === 2,
          });
          console.log('wheel chart - change theme callback - _tvWidget.applyOverrides');

          if (value === Themes.Dark) {
            this.minStrikeColor = this.minStrikeColorForDarkTheme;
            this._tvWidget.changeTheme('Dark');
            console.log('wheel chart - change theme callback - _tvWidget.changeTheme Dark');
          } else if (value === Themes.Light) {
            this.minStrikeColor = this.minStrikeColorForWhiteTheme;
            this._tvWidget.changeTheme('Light');
            console.log('wheel chart - change theme callback - _tvWidget.changeTheme Light');
          }
          this.createMinStrikeLine();
          console.log('wheel chart - change theme callback - createMinStrikeLine');

          await this.createIndicators();
          console.log('wheel chart - change theme callback - createIndicators');

          this.setScaleRange();
          console.log('wheel chart - change theme callback - setScaleRange');
        }),
      );

      this._tvWidget.subscribe('onAutoSaveNeeded', async () => {
        if (!this._listenToDrawEvent) {
          return;
        }

        const scaleMode = this._tvWidget.activeChart().getPanes()[0].getRightPriceScales()[0].getMode();
        if (scaleMode !== this.chartSaveData.priceScaleMode && scaleMode !== 2) {
          this.setScaleRange();
        }

        this._saveChartShapesSubject.next({ symbolId: this._selectedSymbol.security_id, state: this.getUserShapes() });

        await this.saveChartData();
      });
    });
  }

  // only for shapes-restoring, don't put async side effects here
  private restoreUserShapes(savedState): void {
    if (savedState && this._activeChart) {
      try {
        savedState.map((shape) => {
          if (shape.points && shape.points.length > 0) {
            this._activeChart.createMultipointShape(shape.points, shape.props);
          }
        });

        this._lastSavedShapesState = this.getUserShapes().shapes;
      } catch (error) {
        console.warn({ message: 'Cannot restore saved wheel chart shapes', savedState, error });
      }
    }
  }

  async saveChartData(): Promise<void> {
    if (!this._tvWidget || !this._activeChart) {
      return;
    }

    const currentChartData = {
      indicators: this.tradingChartService.getCurrentChartUserIndicators(this._activeChart),
      chartType: this._tvWidget.activeChart().chartType(),
      timeZone: this._tvWidget.activeChart().getTimezoneApi().getTimezone().id,
      priceScaleMode: this._tvWidget.activeChart().getPanes()[0].getRightPriceScales()[0].getMode(),
    };
    this.chartSaveData = await this.tradingChartService.saveChartData(
      currentChartData,
      this.chartSaveData,
      ChartSaveData.Wheel,
      this._tvWidget,
    );
  }

  createMinStrikeLine(): void {
    if (!this._activeChart) {
      return;
    }

    this.removeMinStrikePriceLine();

    if (
      this.strikePriceForShapeData.filteredData?.length !== 0 &&
      this._selectedSymbol &&
      this._selectedSymbol.security_id === this.strikePriceForShapeData.symbolData?.security_id
    ) {
      this.strikePriceForShapeData.minStrikePriceLineId = this._activeChart.createMultipointShape(
        [{ time: moment().unix().valueOf(), price: this.strikePriceForShapeData.symbolData.strike_price }],
        {
          shape: 'horizontal_line',
          lock: true, // is user unable to remove/change/hide the shape
          zOrder: 'top',
          overrides: {
            linecolor: this.minStrikeColor,
            textcolor: this.minStrikeColor,
            linestyle: 2,
            linewidth: 1,
            showLabel: true,
            horzLabelsAlign: 'right',
          },
          disableSelection: true,
          disableUndo: true,
          disableSave: false,
          text: this.minStrikeText,
        },
      );
    }
  }

  setScaleRange(): void {
    if (!this.strikePriceForShapeData.symbolData?.strike_price) {
      return;
    }

    const priceScale = this._tvWidget.activeChart().getPanes()[0].getRightPriceScales()[0];
    const priceScaleRange = priceScale.getVisiblePriceRange();
    const pxOfPriceRange = (priceScaleRange.to - priceScaleRange.from) / this._activeChart.getPanes()[0].getHeight();
    if (
      priceScaleRange.from + pixelCountToSetScaleRangeForMinStrike * pxOfPriceRange >=
      this.strikePriceForShapeData.symbolData.strike_price
    ) {
      const fromPrice =
        this.strikePriceForShapeData.symbolData.strike_price - pixelCountToSetScaleRangeForMinStrike * pxOfPriceRange;
      priceScale.setVisiblePriceRange({
        from: fromPrice,
        to: priceScaleRange.to,
      });
    }
  }

  removeMinStrikePriceLine(): void {
    if (this.strikePriceForShapeData.minStrikePriceLineId) {
      this._activeChart.removeEntity(this.strikePriceForShapeData.minStrikePriceLineId);
      this.strikePriceForShapeData.minStrikePriceLineId = null;
    }
  }

  prePostMarketCallback(data): void {
    if (!this._activeChart || data.symbol !== this._selectedSymbol?.symbol) {
      return;
    }

    if (this._preMarketShapeId && !this.marketTimeService.isPrePostMarketTime()) {
      this._activeChart.removeEntity(this._preMarketShapeId);
      this._preMarketShapeId = null;
    }

    const hasPreMarketData = this._selectedSymbol?.country_code === ExchangeCountriesCodes.US;
    if (
      hasPreMarketData &&
      data &&
      (data.isPreMarket || data.isPostMarket) &&
      this.marketTimeService.isPrePostMarketTime()
    ) {
      if (!this._preMarketShapeId) {
        this._preMarketShapeId = this._activeChart.createMultipointShape([{ time: data.time, price: data.close }], {
          shape: 'horizontal_line',
          lock: true,
          zOrder: 'top',
          overrides: {
            linecolor: '#FB8D00',
            textcolor: '#FB8D00',
            linestyle: 1,
            linewidth: 1,
            showLabel: true,
            horzLabelsAlign: 'right',
          },
          disableSelection: true,
          disableUndo: true,
          disableSave: true,
          text: this.marketTimeService.isPostMarketTime() ? 'Post' : 'Pre',
        });
      } else {
        const preMarket = this._activeChart.getShapeById(this._preMarketShapeId);
        const isDataChanged =
          this.recentMarketUpdate?.symbol !== data.symbol ||
          this.recentMarketUpdate?.time !== data.time ||
          this.recentMarketUpdate?.close !== data.close;

        if (preMarket && isDataChanged) {
          preMarket.setPoints([{ time: data.time, price: data.close }]);
        }
      }
    }
  }

  private async initializeExpectedMove(onlyFirst: boolean, onlySecond: boolean): Promise<void> {
    if (!this.editionsService.isFeatureAvailable(Features.ExpectedMove)) {
      return;
    }

    if (!onlySecond) {
      if (this._activeChart && this._expectedMoveOneUpShapeId) {
        this._activeChart.removeEntity(this._expectedMoveOneUpShapeId);
      }

      if (this._activeChart && this._expectedMoveOneDownShapeId) {
        this._activeChart.removeEntity(this._expectedMoveOneDownShapeId);
      }

      this._expectedMoveOneUpShapeId = null;
      this._expectedMoveOneDownShapeId = null;
    }

    if (!onlyFirst) {
      if (this._activeChart && this._expectedMoveTwoUpShapeId) {
        this._activeChart.removeEntity(this._expectedMoveTwoUpShapeId);
      }

      if (this._activeChart && this._expectedMoveTwoDownShapeId) {
        this._activeChart.removeEntity(this._expectedMoveTwoDownShapeId);
      }

      this._expectedMoveTwoUpShapeId = null;
      this._expectedMoveTwoDownShapeId = null;
    }

    const expectedMoves = await this.expectedMoveService.get(this._selectedSymbol.security_id);

    this._expectedMoveOne = expectedMoves?.find(({ index }) => index === 1);
    this._expectedMoveTwo = expectedMoves?.find(({ index }) => index === 2);

    let item = this.observableService.expectedMoveLiveData.getValue();

    if (!item) {
      const historicalData = await this.historicalDataService.get(this._selectedSymbol.security_id);
      if (historicalData?.length) {
        const historicalItem = historicalData[historicalData.length - 1];
        item = {
          symbol: this._selectedSymbol.symbol,
          time: moment(historicalItem.date).unix() * 1000,
          close: historicalItem.close,
          isWeekend: false,
          isHoliday: false,
          isPreMarket: false,
          isPostMarket: false,
        };
      }
    }

    this._expectedMoveInitialized = false;
    this.expectedMoveCallback(item, false);
  }

  private expectedMoveCallback(data: IMarketData, isStreaming: boolean): void {
    if (
      !data ||
      data.isPreMarket ||
      data.isPostMarket ||
      !this._activeChart ||
      data.symbol !== this._selectedSymbol?.symbol
    ) {
      return;
    }

    if (isStreaming && this._expectedMoveInitialized) {
      return;
    }

    if (!this._expectedMoveInitialized) {
      this._expectedMoveInitialized = true;
    }

    const isDataChanged = this.recentExpectedMove?.time !== data.time || this.recentExpectedMove?.close !== data.close;
    this.recentExpectedMove = data;

    if (this.observableService.showExpectedMoveOneOnWheel.getValue() && this._expectedMoveOne) {
      if (isDataChanged || !this._expectedMoveOneUpShapeId || !this._expectedMoveOneDownShapeId) {
        const upPrice = round(data.close + this._expectedMoveOne.expectedMove, 2);
        const downPrice = round(data.close - this._expectedMoveOne.expectedMove, 2);

        if (upPrice && downPrice) {
          this._expectedMoveOneUpShapeId = this.drawExpectedMoveLine({
            time: data.time,
            price: upPrice,
            text: '',
            color: expectedMove1LineColor,
            existingShapeId: this._expectedMoveOneUpShapeId,
          });

          this._expectedMoveOneDownShapeId = this.drawExpectedMoveLine({
            time: data.time,
            price: downPrice,
            text: '',
            color: expectedMove1LineColor,
            existingShapeId: this._expectedMoveOneDownShapeId,
          });
        }
      }
    }

    if (this.observableService.showExpectedMoveTwoOnWheel.getValue() && this._expectedMoveTwo) {
      if (isDataChanged || !this._expectedMoveTwoUpShapeId || !this._expectedMoveTwoDownShapeId) {
        const upPrice = round(data.close + this._expectedMoveTwo.expectedMove, 2);
        const downPrice = round(data.close - this._expectedMoveTwo.expectedMove, 2);

        if (upPrice && downPrice) {
          this._expectedMoveTwoUpShapeId = this.drawExpectedMoveLine({
            time: data.time,
            price: upPrice,
            text: '',
            color: expectedMove2LineColor,
            existingShapeId: this._expectedMoveTwoUpShapeId,
          });

          this._expectedMoveTwoDownShapeId = this.drawExpectedMoveLine({
            time: data.time,
            price: downPrice,
            text: '',
            color: expectedMove2LineColor,
            existingShapeId: this._expectedMoveTwoDownShapeId,
          });
        }
      }
    }
  }

  private drawExpectedMoveLine(data: IDrawExpectedMoveLineParams): EntityId {
    const { time, price, text, color, existingShapeId } = data;

    if (!time || !price) {
      return;
    }

    if (existingShapeId) {
      const shape = this._activeChart.getShapeById(existingShapeId);

      if (shape) {
        shape.setPoints([{ time, price }]);
        return existingShapeId;
      }
    }

    const shapeId = this._activeChart.createMultipointShape([{ time, price }], {
      shape: 'horizontal_line',
      lock: true,
      zOrder: 'top',
      overrides: {
        linecolor: color,
        textcolor: color,
        linestyle: 2,
        linewidth: 1,
        showLabel: true,
        horzLabelsAlign: 'right',
      },
      disableSelection: true,
      disableUndo: true,
      disableSave: true,
      text,
    });

    return shapeId;
  }

  createPreMarketDataCallback(onRealtimeCallback) {
    const isCrypto = this._selectedSymbol?.country_code === ExchangeCountriesCodes.CC;
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return (data) => {
      this.prePostMarketCallback(data);
      this.expectedMoveCallback(data, true);

      this.recentMarketUpdate = {
        symbol: data.symbol,
        close: round(data.close, 2),
        time: data.time,
        isWeekend: data.isWeekend,
        isHoliday: data.isHoliday,
        isPreMarket: data.isPreMarket,
        isPostMarket: data.isPostMarket,
      };

      if (
        data &&
        !data.isPreMarket &&
        !data.isPostMarket &&
        (isCrypto || !this.marketTimeService.isBeforeMarketTime())
      ) {
        onRealtimeCallback(data);
      }
    };
  }

  createMarketDataHandler = (data): void => {
    if (!this._activeChart || data.symbol !== this._selectedSymbol?.symbol) {
      return;
    }

    if (this._preMarketShapeId && !data.volume) {
      this._activeChart.removeEntity(this._preMarketShapeId);
      this._preMarketShapeId = null;
    }
  };

  dataFeedMethod(): void {
    this.Datafeed = {
      onReady: (cb): void => {
        setTimeout(() => {
          cb(this.chartConfig);
        }, 0);
      },

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback): void => {},

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback): void => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const symbolConfiguration: any = {
          name: `${this._selectedSymbol.symbol}.${this._selectedSymbol.exchange_code}`,
          full_name: this._selectedSymbol.description,
          description: this._selectedSymbol.description,
          type: 'stock',
          session: '24x7',
          timezone: 'America/New_York',
          ticker: `${this._selectedSymbol.symbol}.${this._selectedSymbol.exchange_code}`,
          data_status: 'pulsed',
          exchange: this._selectedSymbol.exchange_name,
          minmov: 1,
          pricescale: 100000000,
          has_intraday: true,
          intraday_multipliers: ['1', '60'],
          supported_resolutions: this.supportedResolutions,
          volume_precision: 8,
          listed_exchange: 'listed_exchange',
        };
        symbolConfiguration.pricescale = 100;
        setTimeout(() => {
          onSymbolResolvedCallback(symbolConfiguration);
        }, 0);
      },

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      getBars: async (symbolInfo, resolution: any, periodParams, onHistoryCallback, onErrorCallback): Promise<void> => {
        const bars = await this.tradingChartService.getDataForBars(
          this._selectedSymbol,
          periodParams,
          ChartHistoryIntervalMonths,
        );
        onHistoryCallback(bars, { noData: bars.length === 0 });
      },

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getTimescaleMarks: async (symbolInfo, from, to, onDataCallback, resolution): Promise<void> => {
        const markers = await this.tradingChartService.getDataForTimescaleMarks(
          this._selectedSymbol,
          this.chartSaveData.timeZone || EasternTimeZoneName,
          ChartHistoryIntervalMonths,
        );
        onDataCallback(markers);
      },

      subscribeBars: async (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onResetCacheNeededCallback,
      ): Promise<void> => {
        this._subscriberData[subscribeUID] = { ...this._selectedSymbol };
        const processedData = await this.processedDataService.get(this._selectedSymbol.security_id);
        this.streamingService.subscribe(
          subscribeUID,
          this._selectedSymbol,
          processedData,
          this.createPreMarketDataCallback(onRealtimeCallback),
          this.createMarketDataHandler,
        );
      },

      unsubscribeBars: (subscriberUID): void => {
        if (this._subscriberData[subscriberUID]) {
          this.streamingService.unsubscribe(subscriberUID, this._subscriberData[subscriberUID]);
          delete this._subscriberData[subscriberUID];
        }
      },
    };
  }

  protected summaryPanelValueSelected(value: TradingLogSymbolSummaryPanelItemValuesModel): void {
    this.tradingLogSymbolSummaryPanelItemValuesModel = value;
    this.addSummaryPanelLines();
  }

  protected async summaryPanelStateUpdates(value: TradingLogSymbolSummaryPanelStateType): Promise<void> {
    await this.userDataService.set(UserSettings.TradingLogSymbolSummaryPanelWheelState, value);
  }

  protected redirectToLowHighIndicatorDemoPage(): void {
    this.editionsService.redirectToDemoPage(Features.LowHighIndicator);
  }

  private addSummaryPanelLines(): void {
    this.removeLine(this._breakEvenLineData.id);
    this._breakEvenLineData.id = null;

    if (this.tradingLogSymbolSummaryPanelItemValuesModel.breakEven) {
      this._breakEvenLineData.value = this.tradingLogSymbolSummaryPanelItemValuesModel.breakEven;

      this._breakEvenLineData.id = this.createLine(this._breakEvenLineData);
    }

    this.removeLine(this._costBasisLineData.id);
    this._costBasisLineData.id = null;

    if (this.tradingLogSymbolSummaryPanelItemValuesModel.costBasis) {
      this._costBasisLineData.value = this.tradingLogSymbolSummaryPanelItemValuesModel.costBasis;

      this._costBasisLineData.id = this.createLine(this._costBasisLineData);
    }
  }

  private removeLine(id?: EntityId): void {
    if (this._activeChart && id) {
      this._activeChart.removeEntity(id);
    }
  }

  private createLine(lineData: CustomChartLineDataModel): EntityId | null {
    if (!this._activeChart) {
      return null;
    }

    if (lineData.id) {
      this.removeLine(lineData.id);
    }

    return this._activeChart.createMultipointShape([{ time: moment().unix().valueOf(), price: lineData.value }], {
      shape: 'horizontal_line',
      lock: true, // is user unable to remove/change/hide the shape
      zOrder: 'top',
      overrides: {
        linecolor: lineData.color,
        textcolor: lineData.color,
        linestyle: 2,
        linewidth: 1,
        showLabel: true,
        horzLabelsAlign: 'right',
      },
      disableSelection: true,
      disableUndo: true,
      disableSave: true,
      text: lineData.lineText,
    });
  }
}
