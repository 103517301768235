import { isNullOrUndefinedOrEmpty } from '@u/utils/utils';

export const transformShortNumberToFullNumber = (value: string, defaultValue: number = 0): number => {
  if (isNullOrUndefinedOrEmpty(value)) {
    return defaultValue;
  }

  const suffix = value.charAt(value.length - 1);
  let shortcutValue;

  switch (suffix) {
    case 'K':
      shortcutValue = Math.pow(10, 3);
      break;
    case 'M':
      shortcutValue = Math.pow(10, 6);
      break;
    case 'B':
      shortcutValue = Math.pow(10, 9);
      break;
    case 'T':
      shortcutValue = Math.pow(10, 12);
      break;
    default:
      shortcutValue = 1;
  }

  return Number(value.replace(/[KMBT]/g, '')) * shortcutValue;
};
