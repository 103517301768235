import { EnvironmentModel, EnvironmentNames } from '@mod/common/environment.model';


export const environment: EnvironmentModel = {
  production: true,
  name: EnvironmentNames.Dev,
  BaseUrl: 'https://api-pxo2dev.rockwelltrading.com',
  WsBaseUrl: 'https://api-pxo2dev.rockwelltrading.com',
  WsMultiInstanceUrl: 'https://api-pxo2dev.rockwelltrading.com',
  SwitchSiteUrl: 'https://pxo1.rockwelltrading.com',
  TokenExpirationCheckIntervalMS: 1000,
  UpdateTokenByUserActivityDelaySec: 15,
  TokenExpirationCheckSafeTimePeriodSec: 30,
  SyncfusionKey: 'ORg4AjUWIQA/Gnt2VVhjQlFaclZJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhWH9XcXVVRWRVVEQ=',
  SentryDsn: 'https://f199901b624043dfa655542ce0af39b3@o4503942468665344.ingest.sentry.io/4504089872039936',
  SentryAllowedUrls: ['https://pxo.rockwelltrading.com'],
  VideoHubConfiguration: {
    apiKeys: {
      youtube: 'AIzaSyCWkZOdQhfL5b9Cff2WV-T7_5hFa8j4ybk',
      vimeo: 'df8e295370bdc5273b8bd736fdc7c2b5',
    },
    wordPressDomainName: 'https://pxo-res.rockwelltrading.com/wp-json/wp/v2/'
  },
};
