import { AfterContentChecked, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  allExchangeCountryCodes,
  ExchangeCountries,
  ExchangeCountriesCodes,
  getRegExpWithSpecialCharacters,
  Themes,
  WatchlistType,
} from '@const';
import { ObservableService } from '@s/observable.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { IWatchlistData, WatchlistDataService } from '@s/watchlist-data.service';
import { TypedCallback } from '@t/common/calback.types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ISearchDialogData {
  searchString: string;
  isPowerX: boolean;
  selectInput: boolean;
  showAddWatchlistButton: boolean;
  symbolSources: ReadonlyArray<ExchangeCountriesCodes>;
  callbackInsteadOfClose?: TypedCallback<ISymbol>;
  clearSearchStringAfterSelection?: boolean;
  stickTo?: HTMLElement;
  scrollElement?: HTMLElement;
  customHeader?: string;
}

const defaultSettings: ISearchDialogData = {
  searchString: '',
  isPowerX: false,
  selectInput: false,
  showAddWatchlistButton: false,
  symbolSources: allExchangeCountryCodes,
  clearSearchStringAfterSelection: false,
};

@Component({
  selector: 'app-symbol-search-modal',
  templateUrl: './symbol-search-modal.component.html',
  styleUrls: ['./symbol-search-modal.component.scss'],
})
export class SymbolSearchModalComponent implements OnInit, AfterContentChecked, OnDestroy {
  searchString = '';
  symbolData: ISymbol[] = [];
  watchlistData: IWatchlistData[] = [];
  allSymbols: ISymbol[] = [];
  ExchangeCountries = ExchangeCountries;
  isPowerX: boolean;
  private onDestroy$ = new Subject();

  @ViewChild('myinput') myInputField: ElementRef;

  get addSvg() {
    const theme = this.observableService.theme.value;
    return `../../../../../assets/img/add-s${theme === Themes.Dark ? '-white' : ''}.svg`;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISearchDialogData,
    private observableService: ObservableService,
    private watchlistDataService: WatchlistDataService,
    private dialogRef: MatDialogRef<SymbolSearchModalComponent>,
    private symbolsService: SymbolsService
  ) {
    this.data = { ...defaultSettings, ...data };
  }

  async ngOnInit(): Promise<void> {
    this.watchlistData = this.data.showAddWatchlistButton ? await this.watchlistDataService.get(WatchlistType.PowerX) : [];
    this.updateWatchlist();
    this.observableService.watchlistUpdated.pipe(takeUntil(this.onDestroy$)).subscribe(this.updateWatchlist);

    const symbols = await this.symbolsService.getAll();
    this.allSymbols = [...symbols].sort((a, b) => a.symbol.localeCompare(b.symbol));

    this.searchString = this.data.searchString;
    this.isPowerX = this.data.isPowerX;
    this.searchSymbol();
    setTimeout(() => {
      if (this.data.selectInput) {
        this.myInputField.nativeElement.select();
      }
    }, 0);
    this.updatePosition();
  }

  ngAfterContentChecked(): void {
    setTimeout(() => {
      this.myInputField.nativeElement.focus();
    }, 0);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateWatchlist = async () => {
    this.watchlistData = await this.watchlistDataService.get(WatchlistType.PowerX);
  };

  clearSearchResult() {
    this.searchString = '';
    this.searchSymbol();
    this.myInputField.nativeElement.focus();
  }

  onEnterButton() {
    if (this.symbolData.length) {
      this.selectSymbol(this.symbolData[0]);
    }
  }

  searchSymbol() {
    const strLength = this.searchString.length;
    if (this.searchString && strLength) {
      const regExpStr = getRegExpWithSpecialCharacters(this.searchString);
      const symbolSearchRegex = new RegExp('^' + regExpStr, 'i');
      const descriptionSearchRegex = new RegExp(regExpStr, 'i');

      this.symbolData = [...this.allSymbols]
        .filter((s) =>
          strLength === 1
            ? s.symbol.match(symbolSearchRegex)
            : s.symbol.match(symbolSearchRegex) || (s.description && s.description.match(descriptionSearchRegex))
        )
        .sort((a, b) => {
          const symbol1Matches = a.symbol.match(symbolSearchRegex);
          const symbol2Matches = b.symbol.match(symbolSearchRegex);

          if (symbol1Matches && !symbol2Matches) {
            return -1;
          }

          if (!symbol1Matches && symbol2Matches) {
            return 1;
          }

          return 0;
        });
    } else {
      this.symbolData = [...this.allSymbols];
    }

    this.symbolData = this.symbolData
      .filter((s) => this.data.symbolSources.find((source) => source === s.country_code))
      .sort((a, b) => {
        if (a.symbol === b.symbol) {
          return a.country_code > b.country_code ? -1 : a.country_code < b.country_code ? 1 : 0;
        }
      });
  }

  selectSymbol(symbol: ISymbol) {
    if (!this.data.showAddWatchlistButton) {
      if (this.data.callbackInsteadOfClose) {
        this.data.callbackInsteadOfClose(symbol);
        setTimeout(() => {
          this.updatePosition();
        }, 100);
      } else {
        this.dialogRef.close(symbol);
      }

      if (this.data.clearSearchStringAfterSelection) {
        this.searchString = '';
        this.searchSymbol();
      }
    }
  }

  getIsDelete(symbol: ISymbol) {
    return this.watchlistData.some((watchlistData) => symbol.security_id === watchlistData.security_id);
  }

  async addToWatchlist(symbol: ISymbol) {
    await this.watchlistDataService.insert(symbol.security_id, WatchlistType.PowerX);
    this.observableService.watchlistUpdated.next(true);
    await this.updateWatchlist();
  }

  async deleteSymbol(symbol: ISymbol) {
    const id = this.watchlistData.find((el) => el.security_id === symbol.security_id)?.id;
    if (id) {
      this.watchlistData = this.watchlistData.filter((el) => el.security_id !== symbol.security_id);
      await this.watchlistDataService.remove(id);
      this.observableService.watchlistUpdated.next(true);
    }
  }

  private updatePosition() {
    if (this.data.stickTo) {
      this.dialogRef.updatePosition({
        top: `${
          this.data.stickTo.offsetTop +
          this.data.stickTo.offsetHeight -
          (this.data.scrollElement ? this.data.scrollElement.scrollTop : 0) +
          5
        }px`,
        left: `${this.data.stickTo.offsetLeft}px`,
      });
    }
  }
}
