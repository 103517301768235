import { Injectable } from '@angular/core';
import { Countries, EasternTimeZoneName, MomentDateTimeFormats } from '@const';
import { IMarketStateChangedEvent, MarketState } from '@mod/data/market-time.model';
import { IWorkingHours, IWorkingHoursTimeDetails } from '@mod/data/working-hours.model';
import * as moment from 'moment';
import { BehaviorSubject, Subject, combineLatest, interval } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarketTimeService {
  public isWeekend$ = new BehaviorSubject<boolean>(false);
  public isHoliday$ = new BehaviorSubject<boolean>(false);
  public isMarketOpened$ = new BehaviorSubject<boolean>(false);
  public isPreMarket$ = new BehaviorSubject<boolean>(false);
  public isPostMarket$ = new BehaviorSubject<boolean>(false);
  public marketStateChanged$ = new Subject<IMarketStateChangedEvent>();

  public marketTime$ = combineLatest([this.isWeekend$, this.isHoliday$, this.isMarketOpened$, this.isPreMarket$, this.isPostMarket$]).pipe(
    map(([isWeekend, isHoliday, isMarketOpened, isPreMarket, isPostMarket]) => {
      return { isWeekend, isHoliday, isMarketOpened, isPreMarket, isPostMarket };
    }),
  );

  private _workingHours: Map<string, IWorkingHours> = null;
  private _holidays: Map<Countries, Map<string, string>> = null;
  private _isInitialized = false;

  static marketStartOffset = { hours: 9, minutes: 30, seconds: 0 };
  static marketEndOffset = { hours: 16, minutes: 0, seconds: 0 };

  static preMarketOffset = { hours: -5, minutes: -30, seconds: 0 };
  static postMarketOffset = { hours: 4, minutes: 0, seconds: 0 };

  constructor() {
    interval(1500).subscribe(() => {
      if (!this._isInitialized) {
        return;
      }

      const currentMarketState: MarketState = {
        isWeekend: this.isWeekend$.getValue(),
        isHoliday: this.isHoliday$.getValue(),
        isPreMarket: this.isPreMarket$.getValue(),
        isMarketOpened: this.isMarketOpened$.getValue(),
        isPostMarket: this.isPostMarket$.getValue(),
      };

      const newMarketState = this._getCurrentState();

      let hasChanges = false;

      if (currentMarketState.isWeekend !== newMarketState.isWeekend) {
        this.isWeekend$.next(newMarketState.isWeekend);
        hasChanges = true;
      }

      if (currentMarketState.isHoliday !== newMarketState.isHoliday) {
        this.isHoliday$.next(newMarketState.isHoliday);
        hasChanges = true;
      }

      if (currentMarketState.isPreMarket !== newMarketState.isPreMarket) {
        this.isPreMarket$.next(newMarketState.isPreMarket);
        hasChanges = true;
      }

      if (currentMarketState.isPostMarket !== newMarketState.isPostMarket) {
        this.isPostMarket$.next(newMarketState.isPostMarket);
        hasChanges = true;
      }

      if (currentMarketState.isMarketOpened !== newMarketState.isMarketOpened) {
        this.isMarketOpened$.next(newMarketState.isMarketOpened);
        hasChanges = true;
      }

      if (hasChanges) {
        this.marketStateChanged$.next({
          prevMarketState: currentMarketState,
          newMarketState,
        });
      }
    });
  }

  public initialize(workingHours: Map<string, IWorkingHours>, holidays: Map<Countries, Map<string, string>>): void {
    this._workingHours = workingHours;
    this._holidays = holidays;

    const { isWeekend, isHoliday, isPreMarket, isMarketOpened, isPostMarket } = this._getCurrentState();

    this.isWeekend$.next(isWeekend);
    this.isHoliday$.next(isHoliday);
    this.isPreMarket$.next(isPreMarket);
    this.isPostMarket$.next(isPostMarket);
    this.isMarketOpened$.next(isMarketOpened);

    this._isInitialized = true;
  }

  // for specific functionality, raw open/close time data
  public getMarketHoursDetails(time?: moment.Moment): {
    marketOpenTime: IWorkingHoursTimeDetails;
    marketCloseTime: IWorkingHoursTimeDetails;
  } {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    const { start, end } = this._getMarketHours(etTime);

    return {
      marketOpenTime: { hours: start.hours(), minutes: start.minutes(), seconds: start.seconds() },
      marketCloseTime: { hours: end.hours(), minutes: end.minutes(), seconds: end.seconds() },
    };
  }

  _getCurrentState(time?: moment.Moment): MarketState {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    const isWeekend = this.isWeekend(etTime);
    const isHoliday = !!this._holidays.get(Countries.USA)?.get(etTime.format(MomentDateTimeFormats.ServerDate));
    const isPreMarket = !isWeekend && !isHoliday && this.isPreMarketTime(etTime);
    const isMarketOpened = !isWeekend && !isHoliday && this.isMarketTime(etTime);
    const isPostMarket = !isWeekend && !isHoliday && this.isPostMarketTime(etTime);

    return {
      isWeekend,
      isHoliday,
      isPreMarket,
      isMarketOpened,
      isPostMarket,
    };
  }

  _getMarketHours(etNow: moment.Moment): { start: moment.Moment; end: moment.Moment } {
    let start = etNow
      .clone()
      .startOf('day')
      .add(MarketTimeService.marketStartOffset.hours, 'hours')
      .add(MarketTimeService.marketStartOffset.minutes, 'minutes');

    let end = etNow
      .clone()
      .startOf('day')
      .add(MarketTimeService.marketEndOffset.hours, 'hours')
      .add(MarketTimeService.marketEndOffset.minutes, 'minutes');

    const specialWorkingHours = this._workingHours?.get(etNow.format(MomentDateTimeFormats.ServerDate));
    if (specialWorkingHours) {
      start = etNow.clone().startOf('day').add(specialWorkingHours.startTime);
      end = etNow.clone().startOf('day').add(specialWorkingHours.endTime);
    }

    return {
      start,
      end,
    };
  }

  isBeforePreMarket(time?: moment.Moment): boolean {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    if (this.isHoliday(etTime) || this.isWeekend(etTime)) {
      return false;
    }

    const { start } = this._getMarketHours(etTime);
    const preMarketStart = start
      .clone()
      .add(MarketTimeService.preMarketOffset.hours, 'hours')
      .add(MarketTimeService.preMarketOffset.minutes, 'minutes');

    return etTime.unix() < preMarketStart.unix();
  }

  isBeforeMarketTime(time?: moment.Moment): boolean {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    if (this.isHoliday(etTime) || this.isWeekend(etTime)) {
      return false;
    }

    const { start } = this._getMarketHours(etTime);

    return etTime.unix() < start.unix();
  }

  isAfterMarketTime(time?: moment.Moment): boolean {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    if (this.isHoliday(etTime) || this.isWeekend(etTime)) {
      return false;
    }

    const { end } = this._getMarketHours(etTime);

    return etTime.unix() > end.unix();
  }

  isPreMarketTime(time?: moment.Moment): boolean {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    if (this.isHoliday(etTime) || this.isWeekend(etTime)) {
      return false;
    }

    const { start } = this._getMarketHours(etTime);
    const preMarketStart = start
      .clone()
      .add(MarketTimeService.preMarketOffset.hours, 'hours')
      .add(MarketTimeService.preMarketOffset.minutes, 'minutes');

    return etTime.unix() >= preMarketStart.unix() && etTime.unix() < start.unix();
  }

  isMarketTime(time?: moment.Moment): boolean {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    if (this.isHoliday(etTime) || this.isWeekend(etTime)) {
      return false;
    }

    const { start, end } = this._getMarketHours(etTime);

    return etTime.unix() >= start.unix() && etTime.unix() < end.unix();
  }

  isPostMarketTime(time?: moment.Moment): boolean {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    if (this.isHoliday(etTime) || this.isWeekend(etTime)) {
      return false;
    }

    const { end } = this._getMarketHours(etTime);
    const postMarketEnd = end
      .clone()
      .add(MarketTimeService.postMarketOffset.hours, 'hours')
      .add(MarketTimeService.postMarketOffset.minutes, 'minutes');

    return etTime.unix() >= end.unix() && etTime.unix() <= postMarketEnd.unix();
  }

  isPrePostMarketTime(time?: moment.Moment): boolean {
    const etTime = time?.isValid() ? time.clone() : moment().tz(EasternTimeZoneName);
    if (this.isHoliday(etTime) || this.isWeekend(etTime)) {
      return false;
    }

    return this.isPreMarketTime(etTime) || this.isPostMarketTime(etTime);
  }

  isHoliday(date: moment.Moment): boolean {
    const isHoliday = !!this._holidays.get(Countries.USA)?.get(date.format(MomentDateTimeFormats.ServerDate));

    return isHoliday;
  }

  isWeekend(date: moment.Moment): boolean {
    return date.isoWeekday() === 6 || date.isoWeekday() === 7;
  }
}
