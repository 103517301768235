import { Flags } from "@mod/symbol-smiley/symbol-smiley.model";

export interface StockScreenerFilterModel {
  flags: Flags[];
  symbolOptions: StockScreenerSymbolsOptions[];
  changePercentFrom: string;
  changePercentTo: string;
  stockPriceFrom: string;
  stockPriceTo: string;
  volumeFrom: VolumeValueModel;
  volumeTo: VolumeValueModel;
}

export interface StockScreenerFilterRequestModel {
  change: { from: string, to: string };
  price: { from: string, to: string };
  volume: { from: string, to: string };
  indexes: Record<string, boolean>;
}

export interface DisplayedFilterValuesModel {
  symbolOptions: string;
  symbolOptionsShort: string;
  changePercent: string;
  stockPrice: string;
  volume: string;
}

export interface VolumeValueModel {
  filterValue: number | string;
  visibleValue: string;
}

export enum TextInputKeys {
  ChangePercentFrom = 'changePercentFrom',
  ChangePercentTo = 'changePercentTo',
  StockPriceFrom = 'stockPriceFrom',
  StockPriceTo = 'stockPriceTo',
  VolumeFrom = 'volumeFrom',
  VolumeTo = 'volumeTo'
}

export enum StockScreenerSymbolsOptions {
  SP100 = 'sp100',
  SP500 = 'sp500',
  Nasdaq = 'nasdaq',
  DowJones = 'dowJones',
  Other = 'other',
}
